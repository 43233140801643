<template>
  <p class="title-p" >优秀表彰</p>
  <div class="ctent" style="position: relative" v-show="!dialogVisible">
    <div class="title-tool department-tool">

      <div style="display: flex;width: 70%;">
        <div class="tool-item">
          <div class="tool-label">
            搜索：
          </div>
          <el-input v-model="search" placeholder="输入标题、作者搜索" style="width: 100%;"/>
        </div>

        <div class="tool-item">
          <div class="tool-label">
            班级：
          </div>
          <el-cascader v-model="schoolClass" :options="njOptions"
					:props="{
					 checkStrictly: true,
					 value:'id',
					 label:'name'
					}"
					ref="cascader"
					@change="setClass"
          />
        </div>

        <div class="tool-item" style="color: #ffffff;">
          <el-button color="#2968D4" type="primary" @click="selectBtn">查 询</el-button>
          <el-button @click="resetBtn">重置</el-button>
        </div>
      </div>

    </div>
    <div class="department-table">
      <el-table :data="userTableData" style="width: 100%" :row-style="{height:'65px'}">
        <el-table-column prop="title" label="标题" align="center" show-overflow-tooltip/>
        <el-table-column prop="author" label="作者" align="center"/>
        <el-table-column prop="digest" label="摘要" align="center" show-overflow-tooltip/>
        <el-table-column prop="banji" label="班级" align="center"/>
        <el-table-column prop="add_time" label="发布时间" align="center"/>
        <el-table-column label="操作">
          <template #default="scope">
            <div style="display: flex; align-items: center">
              <button class="el-button el-button--text el-button--small" type="button"
                      @click="showDepartment(scope.row)" >
                <span style="color: #409eff;">查看</span>
              </button>
             <!-- <el-popconfirm title="确定要删除吗?" @confirm="delDepartment(scope.row)">
                <template #reference>
                  <button class="el-button el-button--text el-button--small" type="button">
                    <span style="color: #f56c6c;">删除</span>
                  </button>
                </template>
              </el-popconfirm> -->
            </div>
          </template>
        </el-table-column>
        <!-- <el-table-column prop="job_number" label="工号"  align="center"/> -->
      </el-table>
    </div>
    <!-- 分页 -->
    <div class="pagination-box">
      <el-pagination
          v-model:currentPage="nowPage"
          v-model:page-size="pageLimit"
          layout="total,prev, pager, next, jumper"
          :total="tableTotal"
          @current-change="handleCurrentChange"
      />
    </div>
  </div>

  <div class="ctent" style="position: relative;padding-top: 50px;height: auto;" v-show="dialogVisible">
	  <div style="position: absolute;left: 10px;top: 10px">
	    <el-button type="primary" plain @click="backForm">返回</el-button>
	  </div>
	  
	  <showDetails :details="detailsData"></showDetails>

  </div>


  <!--11-->
</template>

<script>
import {
  ElMessage,
  ElLoading
} from 'element-plus'
import showDetails from '@/components/showDetails.vue';
export default {
  props:['NewData'],
  emits:['resetdata'],
  name: 'gradeActivities',
  components: {
    showDetails
  },
  data() {
    return {
      dialogVisible:false,
      isSubmit:false,
      search: '',
      dialogTableVisible: false,
      dialogTitle: '',
      userTableData: [],
      nowPage: 1,
      pageLimit: 8,
      tableTotal: 0,
      schoolClass:'',
	  njOptions:[],
	  setClassText:'',
	  detailsData:{}
    }
  },
  methods: {
	  wzlxSelect(val){
	    var ischild = '0';
      requestfaceWithHeadersfn("wechat/index.php","getSchoolClass",{
	      keyword:'getSchoolClass',
	      child:ischild,
	      parent_id:'0'
	    },"01").then((data)=>{
	      //数据返回成功
	      if(data.success){
	        this.njOptions =  data.data.list;
	        // console.log(this.leftTreeList)
	      }
	    }).catch((result=>{
	      //数据返回失败
	    }));
	  },
	  setClass(val){
	    for (let i = 0; i < this.njOptions.length; i++) {
	      if(this.njOptions[i].id === val[0]){
	        this.setClassText = this.njOptions[i].name;
	        if(this.njOptions[i].children && val.length === 2){
	          this.setClassText += "/" + this.$refs["cascader"].getCheckedNodes()[0].label;
	        }
	      }
	    }
	  },
    backForm(){
      this.dialogVisible = false;
	  this.detailsData = {};
    },
    showDepartment(data){
      this.dialogVisible = true
      requestfaceWithHeadersfn("wechat/index.php","articleDetail",{
	    keyword:'articleDetail',
	    media_id:data.media_id,
	  },"01").then((res)=>{
	    //数据返回成功
	    if(res.success){
		  this.detailsData = res.data.detail[data.article_index];
	    }else{
			ElMessage.error(res.msg)
		}
	  }).catch((result=>{
	    //数据返回失败
	  }));
    },
    delDepartment(data){

    },
    handleCurrentChange(data) {
      this.nowPage = data;
      // 用户列表
      this.getUserTableData();
    },
    getUserTableData() {
      requestfaceWithHeadersfn("wechat/index.php","articleList",{
		  keyword:'articleList',
		  type:'优秀表彰',
		  limit: this.pageLimit.toString(),
		  page: this.nowPage.toString(),
		  search:this.search,
		  schoolClass:this.setClassText
		},"01").then((res)=>{
			console.log(res);
		  if (!res.success) {
			  ElMessage.error(res.msg);
			  return
			}
			this.userTableData = res.data.list;
			this.tableTotal = parseInt(res.data.total);
		}).catch((result=>{
		  //数据返回失败
		}));
    },
    selectBtn() {
      // 用户列表
      this.getUserTableData();
    },
    resetBtn() {
      this.search = '';
	  this.schoolClass = '';
	  this.setClassText = '';
    },
  },
  mounted() {
    // 用户列表
    this.getUserTableData();
	// 班级列表
	this.wzlxSelect();
  }
}
</script>

<style scoped>
.department-table {
  width: 100%;
  height: calc(100% - 80px);
  overflow: auto;
  padding-top: 50px;
  box-sizing: border-box;
  position: relative;
}

.pagination-box {
  position: absolute;
  right: 10px;
  bottom: 20px;
}

.ctent {
  width: 100%;
  margin: 0 auto;
  height: calc(100% - 32px);
  padding: 30px;
  box-sizing: border-box;
  background-color: #ffffff;
  border-radius: 10px;
}

.title-tool {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.tool-item {
  width: 26%;
  height: 48px;
  display: flex;
  align-items: center;
  margin-right: 50px;
}

.tool-label {
  width: 80px;
  min-width: 80px;
  color: #303133;
}
.img-flex{
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}
.demo-image__preview{
  width: 12.5%;
  box-sizing: border-box;
  padding: 0 15px 15px 0;
}
</style>